import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';
import Step1 from '../../assets/images/sale_1.jpg';
import Step2 from '../../assets/images/sale_2.png';
import Step3 from '../../assets/images/sale_3.png';

const Sale = () => {
  const { t } = useTranslation();
  const example = [
    {
      name: t('saleItemTitle1'),
      image: Step1,
    },
    {
      name: t('saleItemTitle2'),
      image: Step2,
    },
    {
      name: t('saleItemTitle3'),
      image: Step3,
    },
  ];
  return (
    <section className={'section'} id="sale" style={{ paddingBottom: '36px' }}>
      <Container>
        <SaleWrapper>
          <Title dangerouslySetInnerHTML={{ __html: t('sale_title') }}></Title>
          <SaleContent>
            <Row className={'justify-content-center'}>
              {example.map((item, i) => (
                <Col key={i} lg={3}>
                  <Item
                    className="text-center"
                    style={{ marginBottom: '16px' }}
                  >
                    <CardThumb src={item.image} alt={'image'} />
                    <CardContent>
                      <Text
                        dangerouslySetInnerHTML={{ __html: item.name }}
                      ></Text>
                    </CardContent>
                  </Item>
                </Col>
              ))}
            </Row>
          </SaleContent>
        </SaleWrapper>
      </Container>
    </section>
  );
};

const CardThumb = styled.img`
  border-radius: 16px;
  flex: none;
  width: 100%;
  height: 173px;
  max-width: 100%;
  background-size: cover;
  background-position: center;
  object-fit: cover;

  @media (max-width: 991px) {
    height: 240px;
  }
`;

const CardContent = styled.div`
  width: 100%;
  padding: 16px;
  background: #fff;
  border-radius: 0 0 16px 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
const SaleWrapper = styled.div``;

const Title = styled.h3`
  text-align: center;
  margin-bottom: 30px;
  font-weight: 900;
`;

const Text = styled.div`
  color: black;
  flex-grow: 1;
  @media (max-width: 991px) {
    font-size: 24px;
  }
`;
const SaleContent = styled.div``;
const Item = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  min-height: 290px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
`;

export default memo(Sale);
