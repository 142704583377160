import React, { Component, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import Footer from '../../component/Footer/Footer';
import Banner from './Banner';
import Sale from './Sale';
import ShowAnimation from '../../component/ShowAnimation';

// Importing Section
const Navbar = React.lazy(() => import('../../component/Navbar/NavBar'));
const Video = React.lazy(() => import('./Video'));
const AboutUs = React.lazy(() => import('./AboutUs'));
const AiAdvisor = React.lazy(() => import('./AiAdvisor'));
const Functions = React.lazy(() => import('./Functions'));
const Notice = React.lazy(() => import('./Notice'));
const Started = React.lazy(() => import('./Started'));

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: '',
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: 'nav-sticky', imglight: false });
    } else {
      this.setState({ navClass: '', imglight: false });
    }
  };

  //set preloader div
  PreLoader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { t } = this.props;
    const navItems = [
      { id: 1, idnm: 'home', navheading: t('home') },
      { id: 2, idnm: 'aboutus', navheading: t('aboutus') },
      { id: 3, idnm: 'ai_advisor', navheading: t('ai_advisor') },
      { id: 4, idnm: 'functions', navheading: t('functions_navi') },
      { id: 5, idnm: 'quick_guide', navheading: t('quick_guide') },
    ];
    return (
      <React.Fragment>
        <Suspense fallback={this.PreLoader()}>
          <Navbar
            navItems={navItems}
            navClass={this.state.navClass}
            imglight={this.state.imglight}
          />

          <Banner />

          <Video />

          <ShowAnimation>
            <AboutUs />
          </ShowAnimation>

          <ShowAnimation>
            <Sale></Sale>
          </ShowAnimation>

          <ShowAnimation>
            <AiAdvisor />
          </ShowAnimation>

          <ShowAnimation>
            <Functions />
          </ShowAnimation>

          <ShowAnimation>
            <Notice />
          </ShowAnimation>

          <ShowAnimation>
            <Started />
          </ShowAnimation>

          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}
export default withTranslation()(Home);
