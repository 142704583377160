import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
i18n.use(initReactI18next).init({
  fallbackLng: 'jp',
  lng: 'jp',
  resources: {
    en: {
      translations: require('./en.json')
    },
    jp: {
      translations: require('./jp.json')
    },
  },
  ns: ['translations'],
  defaultNS: 'translations'
});
i18n.languages = ['jp'];
export default i18n;
