import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import styled from 'styled-components';
import logoWhite from '../../assets/images/logoWhite.svg';
import logoMirai from '../../assets/images/logo-mirai.png';
class Footer extends Component {
  render() {
    const t = this.props.t;
    const navigate = [
      {
        id: 1,
        child: [
          { title: t('home'), idnm: 'home' },
          { title: t('aboutus'), idnm: 'aboutus' },
          { title: t('ai_advisor'), idnm: 'ai_advisor' },
          { title: t('functions_navi'), idnm: 'functions' },
          { title: t('quick_guide'), idnm: 'quick_guide' },
        ],
      },
      {
        id: 2,
        child: [
          { logo: logoWhite },
          { title: 'JIFFY Platform ', textBold: false },
          {
            title: 'Email:',
            textBold: true,
            value: 'contact@mirai-plus.co.jp',
          },
          {
            title: 'Tel:',
            textBold: true,
            value: '(+81)70 9133 2266 ',
          },
        ],
      },
      {
        id: 3,
        child: [
          { logo: logoMirai },
          { title: '株式会社みらいプラス' },
          { title: 'AI・Fintech・Offshore開発事業' },
          { title: 'www.mirai-plus.co.jp', linkTo: 'https://mirai-plus.co.jp' },
          { title: '東京都港区芝浦4丁目12番38号' },
          { title: 'CANAL GATE SHIBAURA（キャナル ゲート芝浦）ビル' },
        ],
      },
    ];

    return (
      <React.Fragment>
        {/* Footer Start */}
        <footer className="footer" style={{ backgroundImage: '#435AAD' }}>
          <Container>
            <FooterWrapper>
              {navigate.map((item, key) => (
                <Item key={key} className="reset-padding">
                  <ul className="list-unstyled footer-sub-menu">
                    {item.child.map((linkItem, key) => (
                      <>
                        {linkItem.logo && <Logo src={linkItem.logo}></Logo>}
                        <li key={key}>
                          {linkItem.idnm ? (
                            <a
                              className={`footer-link ${
                                linkItem.textBold ? 'font-weight-bold' : ''
                              }`}
                              href={`#${linkItem.idnm}`}
                            >
                              {linkItem.title + ' '}
                            </a>
                          ) : linkItem.linkTo ? (
                            <a
                              className={`footer-link ${
                                linkItem.textBold ? 'font-weight-bold' : ''
                              }`}
                              href={linkItem.linkTo}
                              target={'_blank'}
                              rel={'noreferrer'}
                            >
                              {linkItem.title}
                            </a>
                          ) : (
                            !linkItem.logo && (
                              <div
                                className={`footer-link`}
                                style={{
                                  cursor: 'pointer',
                                }}
                              >
                                <span
                                  className={`${
                                    linkItem.textBold ? 'font-weight-bold' : ''
                                  }`}
                                >
                                  {linkItem.title + ' '}
                                </span>
                                {linkItem.value ?? ''}
                              </div>
                            )
                          )}
                        </li>
                      </>
                    ))}
                  </ul>
                </Item>
              ))}
            </FooterWrapper>
          </Container>
        </footer>
        {/* Footer End */}
      </React.Fragment>
    );
  }
}
const FooterWrapper = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  @media (max-width: 991px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
  }
  @media (min-width: 767px) and (max-width: 1023px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    justify-content: flex-start;
  }
  @media (max-width: 767px) {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
    justify-content: flex-start;
  }
`;
const Logo = styled.img`
  height: 50px;
`;
const Item = styled.div`
  @media (min-width: 767px) and (max-width: 1023px) {
    justify-content: flex-start !important;
  }
  @media (max-width: 767px) {
    justify-content: flex-start !important;
  }
`;
export default withTranslation()(Footer);
