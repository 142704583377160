import React, { Component } from 'react';
import styled from 'styled-components';

class ShowAnimation extends Component {
  constructor(props) {
    super(props);
    this.refSection = React.createRef();
    this.refContent = React.createRef();
    this.state = {
      isActiveContent: false,
      height: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const ref = this.refSection.current.getBoundingClientRect();
    const refContent = this.refContent.current.getBoundingClientRect();
    this.setState({
      height: refContent.height,
    });
    if (ref.top < (window.innerHeight * 2) / 3) {
      this.setState({
        isActiveContent: true,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <AnimationWrapper
          ref={this.refSection}
          style={{ height: this.props.height }}
        >
          <Animation
            className={this.state.isActiveContent ? 'content show' : 'content'}
            ref={this.refContent}
          >
            {this.props.children}
          </Animation>
        </AnimationWrapper>
      </React.Fragment>
    );
  }
}

const AnimationWrapper = styled.div`
  width: 100%;
  .content {
    opacity: 0;
    transform: translateY(200px);
    transition: all 0.5s;
  }
  .show {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
`;

const Animation = styled.div``;

export default ShowAnimation;
