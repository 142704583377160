import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
// Import Background Image
import styled from 'styled-components';
import hero from '../../assets/images/feature-image.svg';
import Background from '../../assets/images/hero-1-bg-img.png';
class Banner extends Component {
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <section
          className="hero-1-bg bg-light"
          style={{ backgroundImage: `url(${Background})` }}
          id="home"
        >
          <Container className="banner-content">
            <Row className="h-100 justify-content-evenly">
              <Col lg={4}>
                <BannerContent className="hero-1-content">
                  <div className="content">
                    <h1
                      className="headerGrayTitle display-6 header-title color-gray-1 font-weight-semibold mb-4"
                      dangerouslySetInnerHTML={{ __html: t('headerGrayTitle') }}
                    ></h1>
                    <h1 className="hero-1-title font-weight-normal text-dark mb-4">
                      {t('headerBigTitle1')}
                    </h1>
                    <p
                      className="headerBigTitleSubtitle font-weight-semibold mb-4 pb-3"
                      dangerouslySetInnerHTML={{
                        __html: t('headerBigTitleSubtitle1'),
                      }}
                    ></p>
                    <span style={{ fontSize: '28px', fontWeight: 900 }}>
                      {t('bannerSale')}
                    </span>
                  </div>

                  <a
                    href="https://app.jiffy.jp/sign-up"
                    style={{
                      width: 'max-content',
                      borderRadius: '10px',
                    }}
                    className="btn btn-primary"
                  >
                    {t('getStartedFree')}
                    <span
                      className="ml-2 right-icon"
                      style={{
                        marginLeft: '10px',
                      }}
                    >
                      &#8594;
                    </span>
                  </a>
                </BannerContent>
              </Col>
              <Col lg={8} md={10}>
                <div className=" mt-5 mt-lg-0">
                  <img
                    src={hero}
                    alt=""
                    className="img-fluid d-block mx-auto"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

const BannerContent = styled.div`
  @media (max-width: 991px) {
    text-align: center;
    align-items: center;
    .content {
      margin-right: 0;
      margin-bottom: 15px;
    }
    .headerBigTitleSubtitle {
      margin-bottom: 0px !important;
      font-size: 20px;
    }
    .headerGrayTitle {
      margin-bottom: 10px !important;
    }
  }
`;

export default withTranslation()(Banner);
